import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default () => (
	<Layout>
		<SEO title='404: Not found' />
		<h2 className='bg-yellow-200 text-2xl font-bold inline-block my-8 p-3'>
			The URL doesn&apos;t exist, you&apos;ve wandered into uncharted territory :/
		</h2>
	</Layout>
);
